<template>
    <div class="user">
        <page-header></page-header>
        <div class="user-center">
            <user-left></user-left>
            <div>
                <router-view />
            </div>
            <div>
                <love-gift></love-gift>
                <love-wall></love-wall>
            </div>
        </div>
        <page-bottom></page-bottom>
        <page-fixed></page-fixed>
        <transition name="el-zoom-in-top">
            <user-form-list v-if="$store.state.sayShow"></user-form-list>
        </transition>
        <transition name="el-zoom-in-top">
            <pay v-if="$store.state.payShow"></pay>
        </transition>
        <transition name="el-zoom-in-top">
            <upgrade v-if="$store.state.upgradeShow"></upgrade>
        </transition>
    </div>
</template>

<script>
import PageHeader from '../../components/pageHeader.vue'
import UserLeft from '../../components/user/userLeft.vue'
import LoveGift from '../../components/home/loveGift.vue'
import LoveWall from '../../components/home/loveWall.vue'
import PageBottom from '../../components/pageBottom.vue'
import PageFixed from '../../components/pageFixed.vue'
import userFormList from '../../components/say.vue'
import Pay from '../../components/pay.vue'
import Upgrade from '../../components/upgrade.vue'
import { release } from 'os'
export default {
    components: {PageHeader,UserLeft,LoveGift,LoveWall,PageBottom,PageFixed,userFormList,Pay,Upgrade},
    data(){
        return{
            
        }
    }
}
</script>

<style scoped>
.user{
    height: max-content;
    padding-top: 60px;
    position: relative;
}
.user-center{
    width: 1200px;
    height: max-content;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
}

.user-center>div:nth-child(2){
    flex: 1;
    height: max-content;
    margin: 0 18px;
}
.user-center>div:nth-child(3){
    width: 300px;
    height: max-content;
}
</style>