<template>
    <div>
       <el-upload
            name="imgFile"
            class="page-home-user-logo"
            :action="$isApi.uploadImg"
            :show-file-list="false"
            :data="cccdata"
            :on-change='changeUpload'>
            <div class="user-left-logo">
                <div class="user-left-logo-t">{{$t('userLeft.title')}}</div>
                <img class="page-home-user-logo" v-if="sendForm.header" :src="this.$isApi.http + sendForm.header" alt="">
                <img class="page-home-user-logo" v-else :src="userLogo" alt="">
            </div>
        </el-upload>
        <div class="user-left-btn" @click="dialogCrad = true">{{$t('userLeft.cardReal')}}</div>
        <div class="user-left-time">{{$t('userLeft.time')}}：{{$isPulic.timestampToTime(sendForm.createDate)}}</div>
        <div class="user-left-nav">
            <!-- <router-link 
                tag='div' 
                :to="item.url" 
                class="user-left-item" 
                v-for="(item,index) in navList" 
                :key="index">
                <i :style="{color:item.color}" class="iconfont" :class="item.icon"></i>
                <span>{{item.name}}</span>
                <span class="iconfont icon-jiantou" style="font-size:20px"></span>
                <div v-if="item.noReadCount && $store.state.noReadCount > 0">{{$store.state.noReadCount}}</div>
            </router-link> -->
            <router-link tag='div' to="/page/user/information" class="user-left-item">
                <i style="color:#6A83FF" class="iconfont icon-shenfenshibierenzheng"></i>
                <span>{{$t('userLeft.myHome')}}</span>
                <span class="iconfont icon-jiantou" style="font-size:20px"></span>
            </router-link>
            <router-link tag='div' to="/page/user/details" class="user-left-item">
                <i style="color:#0f0" class="iconfont icon-liuzhuan"></i>
                <span>{{$t('userLeft.myContent')}}</span>
                <span class="iconfont icon-jiantou" style="font-size:20px"></span>
            </router-link>
            <router-link tag='div' to="/page/user/state" class="user-left-item">
                <i style="color:#D070F9" class="iconfont icon-dicengjiagou"></i>
                <span>{{$t('userLeft.myState')}}</span>
                <span class="iconfont icon-jiantou" style="font-size:20px"></span>
            </router-link>
            <router-link tag='div' to="/page/user/photo" class="user-left-item">
                <i style="color:#FB5D5E" class="iconfont icon-picture"></i>
                <span>{{$t('userLeft.myPhoto')}}</span>
                <span class="iconfont icon-jiantou" style="font-size:20px"></span>
            </router-link>
            <router-link tag='div' to="/page/user/caller" class="user-left-item">
                <i style="color:#FB5D5E" class="iconfont icon-qukuai"></i>
                <span>{{$t('userLeft.myCaller')}}</span>
                <span class="iconfont icon-jiantou" style="font-size:20px"></span>
                <div v-if="$store.state.noReadCount > 0">{{$store.state.noReadCount}}</div>
            </router-link>
            <router-link tag='div' to="/page/user/getGifts" class="user-left-item">
                <i style="color:#FB5D5E" class="iconfont icon-gift-fill"></i>
                <span>{{$t('userLeft.myGetGifts')}}</span>
                <span class="iconfont icon-jiantou" style="font-size:20px"></span>
                <div v-if="$store.state.giftNumber > 0">{{$store.state.giftNumber}}</div>
            </router-link>
            <!--
            <router-link tag='div' to="/page/user/setGifts" class="user-left-item">
                <i style="color:#FF0000" class="iconfont icon-gift-fill"></i>
                <span>{{$t('userLeft.mySetGifts')}}</span>
                <span class="iconfont icon-jiantou" style="font-size:20px"></span>
            </router-link>
            -->
            <router-link tag='div' to="/page/user/live" class="user-left-item">
                <i style="color:#e400ff" class="iconfont icon-shoucang"></i>
                <span>{{$t('userLeft.myLive')}}</span>
                <span class="iconfont icon-jiantou" style="font-size:20px"></span>
            </router-link>
            <router-link tag='div' to="/page/user/password" class="user-left-item">
                <i style="color:#f00" class="iconfont icon-password"></i>
                <span>{{$t('userLeft.myPassword')}}</span>
                <span class="iconfont icon-jiantou" style="font-size:20px"></span>
            </router-link>
        </div>
        <el-dialog
            :title="$t('userLeft.cardReal')"
            :visible.sync="dialogCrad"
            width="40%">
            <el-form :model="sendForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item :label="$t('sendUser.realName')" prop="realName" style="width:90%">
                    <el-input :disabled="sendForm.isTrueName" size="small" v-model="sendForm.realName"></el-input>
                </el-form-item>
                <el-form-item :label="$t('sendUser.cardID')" prop="cardID" style="width:90%">
                    <el-input :disabled="sendForm.isTrueName" size="small" v-model="sendForm.cardID"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer" v-if="!sendForm.isTrueName">
                <el-button type="primary" @click="submitForm('ruleForm')">{{$t('userLeft.success')}}</el-button>
            </span>
        </el-dialog>
        <el-dialog
            :title="$t('userDetails.headerCai')"
            :visible.sync="cropperShow"
            width="50%">
            <vueCropper
                style="width:100%;height:50vh"
                ref="cropper"
                :img="optionImg.img"
                :outputSize="optionImg.size"
                :outputType="optionImg.outputType"
                :info="true"
                :full="optionImg.full"
                :canMove="optionImg.canMove"
                :canMoveBox="optionImg.canMoveBox"
                :original="optionImg.original"
                :autoCrop="optionImg.autoCrop"
                :fixed="optionImg.fixed"
                :fixedNumber="optionImg.fixedNumber"
                :centerBox="optionImg.centerBox"
                :infoTrue="optionImg.infoTrue"
                :fixedBox="optionImg.fixedBox">
            </vueCropper>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="userImgsuccess">{{$t('userLeft.success')}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {VueCropper} from 'vue-cropper'
import axios from 'axios'
export default {
    components: {VueCropper},
    data(){
        return{
            dialogCrad: false,
            sendForm: {},
            disabled: false,
            cropperShow: false,
            optionImg: {
                img: '', // 裁剪图片的地址
                info: true, // 裁剪框的大小信息
                outputSize: 0.8, // 裁剪生成图片的质量
                outputType: 'jpg', // 裁剪生成图片的格式
                canScale: false, // 图片是否允许滚轮缩放
                autoCrop: true, // 是否默认生成截图框
                autoCropWidth: 300, // 默认生成截图框宽度
                autoCropHeight: 300, // 默认生成截图框高度
                fixedBox: true, // 固定截图框大小 不允许改变
                fixed: true, // 是否开启截图框宽高固定比例
                fixedNumber: [1, 1], // 截图框的宽高比例
                full: true, // 是否输出原图比例的截图
                canMoveBox: false, // 截图框能否拖动
                original: false, // 上传图片按照原始比例渲染
                centerBox: true, // 截图框是否被限制在图片里面
                infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            },
            rules: {
                realName: [
                    { required: true, message: this.$i18n.t('userLeft.realName'), trigger: 'blur' },
                    { min: 2, max: 4, message: this.$i18n.t('userLeft.width'), trigger: 'blur' }
                ],
                cardID: [{ 
                    required: true, 
                    message: this.$i18n.t('userLeft.cardID'), 
                    trigger: 'blur' 
                },{
                    pattern: /^[a-zA-Z][0-9]{9}$/,
                    message: this.$i18n.t('userLeft.yesCardID')
                }]
            },
            giftNumber: 0,
            userLogo: require("../../assets/images/user_logo.jpg"),
            pageNo: 1,
            cccdata: {
                imgType:'chatImg'
            }
        }
    },
    created(){
        this.sendForm = JSON.parse(sessionStorage['sendForm'])
        if(JSON.parse(sessionStorage['sendForm']).isTrueName){
            this.disabled = true
        }else{
            this.disabled = false
        }
        var json = {
            userId: JSON.parse(sessionStorage['sendForm']).userId,
            pageNo: this.pageNo,
            pageSize: 30,
        }
        this.$isAxios.axiosGet(this.$isApi.userVisitor,json,(res)=>{
            if(res.data.result){
                this.$store.state.userVisitor = res.data.result
                this.$store.state.noReadCount = res.data.noReadCount
            }
        })
        this.$isAxios.axiosGet(this.$isApi.findNoRead,{userId:JSON.parse(sessionStorage['sendForm']).userId},(res)=>{
            if(res.data.message == 'success'){
                this.$store.state.giftNumber = res.data.result
            }
        })
    },
    methods: {
        // 裁剪头像
        changeUpload(file, fileList) {
            const isLt5M = file.size / 1024 / 1024 < 5
            if (!isLt5M) {
                this.$message.error(this.$i18n.t('userDetails.size'))
                return false
            }
            // 上传成功后将图片地址赋值给裁剪框显示图片
            this.$nextTick(() => {
                this.optionImg.img = this.$isApi.http + file.response.path
                this.cropperShow = true
            })
        },
        // 上传头像
        userImgsuccess(){
            this.$refs.cropper.getCropBlob((data) => {
                let file = data
                let param = new FormData() 
                param.append('imgFile', data, 'userlogo.jpg')
                param.append('imgType', 'chatImg')
                axios.post(this.$isApi.uploadImg, param)
                .then(response => {
                    if(response.data.result == 'OK'){
                        this.sendForm.header = response.data.path
                        this.$isAxios.axiosPost(this.$isApi.modifyUser,this.sendForm,(res)=>{
                            if(res.data.result == 'OK'){
                                sessionStorage['sendForm'] = JSON.stringify(res.data.userInfo)
                                this.$message({
                                    message: this.$i18n.t('userLeft.successfully'),
                                    type: 'success'
                                })
                                setTimeout(()=>{
                                    this.$router.go(0)
                                },1000)
                            }else{
                                this.$message({
                                    message: this.$i18n.t('userLeft.noSuccessfully'),
                                    type: 'warning'
                                })
                            }
                        })
                    }
                })
            })
        },
        // 修改个人资料
        submitForm(sendForm) {
            this.$refs[sendForm].validate((valid) => {
                if (valid) {
                    this.sendForm["type"] = 'user'
                    this.sendForm["isTrueName"] = false
                    this.$isAxios.axiosPost(this.$isApi.modifyUser,this.sendForm,(res)=>{
                        if(res.data.result == 'OK'){
                            sessionStorage['sendForm'] = JSON.stringify(res.data.userInfo)
                            this.$message({
                                message: this.$i18n.t('userLeft.successfully'),
                                type: 'success'
                            })
                            this.$confirm(this.$i18n.t('login.renzheng'), this.$i18n.t('giftComponents.reminder'), {
                                confirmButtonText: this.$i18n.t('userLeft.success'),
                                cancelButtonText: this.$i18n.t('giftComponents.cancel'),
                                type: 'warning'
                            }).then(() => {
                                setTimeout(()=>{
                                    this.$router.go(0)
                                },1000)
                            }).catch(() => {
                                setTimeout(()=>{
                                    this.$router.go(0)
                                },1000)
                            })
                            
                        }else{
                            this.$message({
                                message: this.$i18n.t('userLeft.noSuccessfully'),
                                type: 'warning'
                            })
                        }
                        this.dialogCrad = false
                    })
                } else {
                    return false;
                }
            })
        }
    }
}
</script>

<style scoped>

.user-center>div:nth-child(1){
    width: 220px;
    height: max-content;
    padding: 10px;
    background: #fff;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, .1);
}
.user-left-logo{
    height: 260px;
    width: 100%;
    background: #ccc;
    position: relative
}
.user-left-logo>img{
    width: 100%;
    height: 100%;
    object-fit: cover
}
.user-left-btn{
    height: 36px;
    text-align: center;
    line-height: 36px;
    background: #FD463E;
    color: #fff;
    font-size: 14px;
    margin-top: 8px;
    border-radius: 2px;
    cursor: pointer;
}
.user-left-btn:hover{
    background: #f00
}
.user-left-logo-t{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: max-content;
    background: rgba(0, 0, 0, .6);
    padding: 14px;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.user-left-time{
    font-size: 12px;
    color: #999;
    margin-top: 10px
}
.user-left-nav{
    margin-top: 20px;
}
.user-left-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: max-content;
    padding: 10px;
    border-top: 1px dashed #ccc;
    cursor: pointer;
    border-radius: 3px;
    overflow: hidden;
    position: relative;
}
.user-left-item>div{
    position: absolute;
    width: max-content;
    height: 18px;
    line-height: 18px;
    border-radius: 9px;
    padding: 0 6px;
    background: #f00;
    color: #fff;
    left: 120px;
    font-size: 12px;
    font-weight: 100;
}
.user-left-item:first-child{
    border: none
}
.user-left-item>i{
    display: block;
    font-size: 26px;
    width: 26px;
    height: 26px;
    flex-grow: 0
}
.user-left-item>span:nth-child(2){
    font-size: 14px;
    color: #333;
    line-height: 22px;
    flex: 1;
    margin-left: 10px;
}
.user-left-item>span:nth-child(3){
    font-size: 10px;
    line-height: 18px;
    flex-grow: 0;
    color: rgba(0, 0, 255, .6);
    width: max-content;
    padding: 0 6px;
    /* border: 1px solid rgba(0, 0, 255, .6); */
    border-radius: 2px;
    cursor: pointer;
}
.router-link-exact-active{
    background-image: linear-gradient(135deg, #ffcc00, #e400ff);
}
.router-link-exact-active span{
    color: #fff !important;
}
</style>
